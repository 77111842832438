import React from 'react'

const DfpSlotsScript = ({
  adsData = [],
  targets = {},
  suppressAds = false,
}) => {
  // Added check to clear all ad slots when suppressAds is true.
  if (suppressAds) {
    const clearScript = `
      if (typeof window !== 'undefined' && window.googletag && typeof window.googletag.destroySlots === 'function') {
          window.googletag.destroySlots();
      }
      // ...any other clean up...
    `
    return typeof window !== 'undefined' ? (
      <script dangerouslySetInnerHTML={{ __html: clearScript }} />
    ) : null
  }

  const scriptContent = `
    if (typeof window !== 'undefined') {
      window.googletag = window.googletag || { cmd: [] };
      window.gptInitialized = false;
      window.gptRefreshTimer = window.gptRefreshTimer || null;
      window.lastRefreshTime = window.lastRefreshTime || 0;

      window.googletag.cmd.push(() => {
        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().disableInitialLoad();

        const REFRESH_INTERVAL = 45000;
        const MIN_REFRESH_DELAY = 1000;

        if (window.gptRefreshTimer) {
          clearInterval(window.gptRefreshTimer);
          window.gptRefreshTimer = null;
        }

        const refreshAds = () => {
          if (document.hasFocus()) {
            const now = Date.now();
            if (now - window.lastRefreshTime >= MIN_REFRESH_DELAY) {
              const slots = Object.values(window.gptSlots || {}).filter(slot => !slot.lazyLoad);
              if (slots.length > 0) {
                window.googletag.pubads().refresh(slots);
                window.lastRefreshTime = now;
              }
            }
          }
        };

        const startRefreshTimer = () => {
          if (!window.gptRefreshTimer) {
            window.gptRefreshTimer = setInterval(refreshAds, REFRESH_INTERVAL);
          }
        };

        const stopRefreshTimer = () => {
          if (window.gptRefreshTimer) {
            clearInterval(window.gptRefreshTimer);
            window.gptRefreshTimer = null;
          }
        };

        document.addEventListener('visibilitychange', () => {
          if (document.hidden) {
            stopRefreshTimer();
          } else {
            startRefreshTimer();
          }
        });

        startRefreshTimer();

        ${Object.entries(targets)
          .map(
            ([key, value]) =>
              `window.googletag.pubads().setTargeting('${key}', '${value}');`
          )
          .join('')}

        window.gptSlots = window.gptSlots || {};

        ${(adsData || [])
          .map(
            ad => `
            try {
              const sizeMapping = window.googletag.sizeMapping()
                ${ad.sizeMapping
                  .map(
                    ([viewportWidth, size]) =>
                      `.addSize([${viewportWidth}, 0], [${size}])`
                  )
                  .join('')}
                .build();

              const slot = window.googletag
                .defineSlot('${ad.slotId}', ${JSON.stringify(ad.sizes)}, '${
              ad.divId
            }')
                .defineSizeMapping(sizeMapping);

              const divId = '${ad.divId}';
\              if ('${ad.slotId}'.includes('CH01_MiddleBanner') || '${
              ad.slotId
            }'.includes('CH01_BottomoBanner')) {
                slot.setForceSafeFrame(true)
                    .setTargeting('lazyload', 'true');
                slot.lazyLoad = true;
                slot._divId = divId;

                const attachObserver = () => {
                  const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                      if (entry.isIntersecting) {
                        window.googletag.pubads().refresh([slot]);
                        observer.unobserve(entry.target);
                      }
                    });
                  }, { rootMargin: '200px', threshold: 0.1 });
                  const element = document.getElementById(divId);
                  if (element) {
                    observer.observe(element);
                  }
                };

                if (document.readyState === 'complete' || document.readyState === 'interactive') {
                  attachObserver();
                } else {
                  document.addEventListener('DOMContentLoaded', attachObserver);
                }
              }

              slot.addService(window.googletag.pubads());
              window.gptSlots['${ad.divId}'] = slot;
            } catch (error) {
              console.error('Error defining slot for ${ad.divId}:', error);
            }
          `
          )
          .join('')}

        if (!window.gptSlots['anchor']) {
          try {
            const anchorMapping = window.googletag.sizeMapping()
              .addSize([768, 0], [728, 90])
              .addSize([0, 0], [320, 50])
              .build();

            const anchorSlot = window.googletag
              .defineSlot('/13970653/anchor', [[728, 90], [320, 50]], 'anchor')
              .defineSizeMapping(anchorMapping)
              .setTargeting('anchor_position', 'bottom')
              .addService(window.googletag.pubads());
            window.gptSlots['anchor'] = anchorSlot;
          } catch (error) {
            console.error('Error defining anchor slot:', error);
          }
        }

        window.googletag.enableServices();
        try {
          window.googletag.display('anchor');
          if (window.gptSlots['anchor']) {
            window.googletag.pubads().refresh([window.gptSlots['anchor']]);
          }
          const nonLazySlots = Object.values(window.gptSlots || {}).filter(slot => !slot.lazyLoad && slot !== window.gptSlots['anchor']);
          if (nonLazySlots.length > 0) {
            window.googletag.pubads().refresh(nonLazySlots);
          }
        } catch (e) {
          console.error('Error displaying anchor ad:', e);
        }

        window.gptInitialized = true;
      });
    }
  `

  return typeof window !== 'undefined' ? (
    <script dangerouslySetInnerHTML={{ __html: scriptContent }} />
  ) : null
}

export default DfpSlotsScript
