import React from 'react'
import DfpSlotsScript from './DfpSlotsScript'

const AdHead = ({ adsData, targets, suppressAds = false }) => {
  return (
    <>
      <script
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        async
      ></script>
      <DfpSlotsScript
        adsData={adsData}
        targets={targets}
        suppressAds={suppressAds}
      />
    </>
  )
}

export default AdHead
